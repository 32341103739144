const data = [
	{
		id: 'category-1',
		subcategories: [
			{
				id: 'subcategory-1',
				title: 'Buitenmuur isoleren',
				subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
				content:
					'Bij een enkelsteens (ongeïsoleerde) buitenmuur is spouwisolatie toepassen niet mogelijk. In plaats daarvan kun je aan de binnenzijde een voorzetwand plaatsen met een houten stijl- en regelwerk. Voor een goede isolatiewaarde vul je de ruimte tussen en eventueel achter de stijlen met een zo dik mogelijke laag glaswol of steenwol. Houd wel rekening met ruimteverlies in je woning.',
				attachment: 'https://www.youtube.com/embed/AdY0_oA0YuE',
				attachmentType: 'youtube',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/muur-isoleren-buitenkant',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-2',
				title: 'Geluidsisolatie aanbrengen',
				subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
				content:
					'Geluidsisolatie pas je toe wanneer je geluidsoverlast ervaart door contactgeluid. Dit is geluid dat ontstaat via een vaste verbinding met de aangrenzende ruimte. Door speciale geluidsisolerende platen op je muur te monteren houd je geluid buiten en veroorzaak je zelf ook minder geluidsoverlast. Effectieve geluidsisolatie maak je met geluidsisolerende materialen van katoen of kunststof.',
				attachment: 'geluidsisolatie.jpg',
				attachmentType: 'image',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/geluidsisolatie-muur-aanbrengen',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-3',
				title: 'Binnenmuur isoleren',
				subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
				content:
					'Je binnenmuur isoleren doe je meestal als de muur tussen een onverwarmde en een verwarmde ruimte staat. Dit is bijvoorbeeld het geval bij een inpandige berging of een aanbouwkeuken. Door je binnenmuur te isoleren voorkom je onnodig warmteverlies, waardoor je minder hard hoeft te stoken en energie kunt besparen. Daarnaast werkt binnenmuurisolatie geluidswerend.',
				attachment: 'binnenmuurisoleren.jpg',
				attachmentType: 'image',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/muur-isoleren-van-binnenuit',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-4',
				title: 'Spouwmuur isolatie',
				subtitle: 'KLUSNIVEAU: KLUSSERVICE',
				content:
					'Wil je je spouwmuur laten isoleren? Dat is een relatief goedkope isolatieoplossing en levert snel een hoge besparing op. Een spouwmuur bestaat uit twee muren met ruimte ertussen (de spouw). Bij spouwmuurisolatie wordt de spouw door een professional gevuld met isolatiemateriaal. Meestal is deze isolatieklus binnen een dag geklaard, zonder gedoe of rommel in huis.',
				attachmentType: 'image',
				buttons: [
					{
						id: 'button 1',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-5',
				title: 'Gevel impregneren',
				subtitle: 'KLUSNIVEAU: KLUSSERVICE',
				content:
					'Gevelimpregnatie zorgt voor een transparante en waterdichte beschermlaag op je buitenmuur. Hierdoor kan vocht of kou je woning niet meer binnendringen. Zo gaat je gevel langer mee, houd je je woning lekker warm en bespaar je stookkosten. Voor de hoogste isolatiewaarde kun je gevelimpregnatie het best combineren met een andere vorm van gevelisolatie. Denk bijvoorbeeld aan spouwmuurisolatie.',
				attachment: 'klusservice.jpg',
				attachmentType: 'image',
				buttons: [
					{
						id: 'button 1',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
		],
	},
	{
		id: 'category-2',
		subcategories: [
			{
				id: 'subcategory-6',
				title: 'Houten vloer isoleren',
				subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
				content:
					'Door grote temperatuurverschillen kan hout uitzetten en krimpen. Daarom is je houten vloer isoleren vanuit de kruipruimte een goed idee. Een makkelijke en goedkope manier van isoleren – je hoeft zo namelijk niet je hele vloer te vervangen. Isolatiemateriaal wordt via de kruipruimte aan de onderkant van de vloer geplaatst. Dit zorgt voor een stabielere temperatuur in je woonruimte.',
				attachment: 'https://www.youtube.com/embed/IQJ3EhJnv3s',
				attachmentType: 'youtube',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/kruipruimte-isoleren',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-7',
				title: 'Bodem kruipruimte isoleren ',
				subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
				content:
					'Is vloerisolatie niet mogelijk? Dan kun je ook de bodem van je kruipruimte isoleren. Het isoleren van de bodem in je kruipruimte doe je met behulp van EPS-parels. Een duurzame én effectieve oplossing voor een vochtige kruipruimte, waardoor kou je woning niet meer binnen kan dringen. Voor de meeste energiebesparing kun je bodemisolatie en vloerisolatie combineren.',
				attachment: 'https://www.youtube.com/embed/IQJ3EhJnv3s',
				attachmentType: 'youtube',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/kruipruimte-isoleren-bodemfolie',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-8',
				title: 'Betonnen vloer isoleren',
				subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
				content:
					'Een betonnen vloer isoleren? Dat kun je het best vanuit de kruipruimte doen. Je betonnen vloer isoleren doe je door isolatiemateriaal via de kruipruimte aan de onderzijde van je vloer te plaatsen. Zo verdwijnt er minder warmte naar de kruipruimte en komt er minder kou vanuit de kruipruimte je woning in. Bovendien zorgt het voor een vochtvrije woning.',
				attachment: 'betonnenvloer.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/vloerisolatie-betonvloer-aanbrengen',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
		],
	},
	{
		id: 'category-3',
		subcategories: [
			{
				id: 'subcategory-10',
				title: 'LEDverlichting',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Ledverlichting is een energiezuinig alternatief voor halogeenlampen, spaarlampen en gloeilampen. Ledlampen hebben een betere lichtkwaliteit en gaan veel langer mee – vaak wel zo’n 15 tot 20 jaar. Bovendien verbruik je met een ledlamp veel minder energie. Zo maak je minder stroomkosten en kies je voor duurzame verlichting in huis.',
				attachment: 'ledverlichting.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Bekijk het assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=ledverlichting&f_type_artikel=LED',
					},
				],
			},
			{
				id: 'subcategory-11',
				title: 'Slimme verlichting',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Slimme verlichting bedien je via een app op je telefoon of tablet. Via de app zet je lampen aan of uit, of kun je ze dimmen. Laat lampen alleen branden als het nodig is door specifieke dag- en weekritmes in te stellen. Lampen gaan dan pas aan op het aangegeven tijdstip. Zo branden lampen nooit onnodig en bespaar je energie.',
				attachment: 'ledverlichting.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/l/verlichting/slimme-verlichting?redirect=true',
					},
					{
						id: 'button 2',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/smart-led',
					},
				],
			},
			{
				id: 'subcategory-12',
				title: 'Bewegingssensor',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Met een bewegingssensor kun je energiezuinig verlichten. De lamp springt dan pas aan op het moment dat er beweging wordt gedetecteerd in een ruimte. Als een ruimte even niet wordt gebruikt, gaat de lamp vanzelf uit. Zo bespaar je energie. Buiten zorgt een bewegingssensor ook voor een veiligere leefomgeving.',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Bekijk het assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=bewegingssensor',
					},
				],
			},
			{
				id: 'subcategory-13',
				title: 'Energiemeter',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Met een energiemeter meet je snel hoeveel stroom elk apparaat in huis verbruikt. Steek de energiemeter in het stopcontact en steek de stekker van het apparaat waarvan je het energieverbruik wilt meten in de energiemeter. Zo spoor je grote energieverspillers snel op en kun je efficiënter omgaan met je energieverbruik.',
				attachment: 'energiemeter.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Bekijk het assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=energiemeter',
					},
				],
			},
		],
	},
	{
		id: 'category-4',
		subcategories: [
			{
				id: 'subcategory-14',
				title: 'Waterbesparende douchekop',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Met een waterbesparende douchekop verspil je minder water. Zo wordt er bij deze douchekoppen een waterbesparende ring meegeleverd. Hiermee kun je tot 40% water besparen. Daarnaast zorgt een duurzame technische toepassing van luchtmening voor een vollere waterstraal, meer douchecomfort en de nodige waterbesparing.',
				attachment: 'douchekop.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=douchekop',
					},
					{
						id: 'button 2',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/thermostaatkraan-vervangen',
					},
				],
			},
		],
	},

	{
		id: 'category-5',
		subcategories: [
			{
				id: 'subcategory-16',
				title: 'schuin dak isoleren',
				subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
				content:
					'Een schuin dak isoleren? Je huis isoleren zorgt ervoor dat je minder energie verspilt en comfortabeler woont. Je schuine dak van binnenuit isoleren zorgt gemiddeld voor een besparing van 700 euro per jaar. Een schuin dak isoleren doe je meestal met glaswol of steenwol. Deze isolatiematerialen zijn verkrijgbaar in rollen of isolatieplaten.',
				attachment: 'https://www.youtube.com/embed/E-MI4FCPOYM',
				attachmentType: 'youtube',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/schuin-dak-isoleren',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},

			{
				id: 'subcategory-18',
				title: 'Houten plafond isoleren',
				subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
				content:
					'Gebruiken jullie de zolderruimte niet? Of is jullie zolder alleen toegankelijk via een luik? Dan is het handig om het houten plafond onder de zondervloer te isoleren. Zo kun je de kou van je ongebruikte zolder buiten je woonruimte houden. Hierdoor hoef je minder te stoken en bespaar je energie. Bovendien werkt plafondisolatie geluiddempend.',
				attachment: 'https://www.youtube.com/embed/bjtIFzOuWiM',
				attachmentType: 'youtube',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/plafond-isoleren',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-19',
				title: 'Plat dak isoleren',
				subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
				content:
					'De warmte binnenhouden in je woning? Dan is je platte dak van binnenuit isoleren een goed idee. Laat de constructie van je dak vooraf goed onderzoeken. Zorg daarnaast voor een gecontroleerde vochthuishouding. Hier kun je ook een professional voor inhuren. Met een van binnenuit geïsoleerd dak houd je de warmte binnen en maak je minder stookkosten.',
				attachment: 'https://www.youtube.com/embed/qvE69YuNeQs',
				attachmentType: 'youtube',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/plat-dak-isoleren-binnenzijde',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
		],
	},
	{
		id: 'category-6',
		subcategories: [
			{
				id: 'subcategory-20',
				title: 'Zonnepanelen',
				subtitle: 'KLUSNIVEAU: KLUSSERVICE',
				content:
					'Zonnepanelen zijn een slimme en duurzame investering. Met zonnepanelen wek je namelijk je eigen energie op. De levensduur van zonnepanelen is 25 tot soms zelfs 35 jaar, met een gemiddeld rendement tussen de 8% en 14%. Je eigen zonnepanelen zijn dus niet alleen duurzaam, je bespaart hiermee ook de nodige energiekosten.',
				attachment: 'zonnepanelen.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/assortiment/zonnepanelen-inclusief-installatie?redirect=true',
					},
				],
			},
		],
	},
	{
		id: 'category-7',
		subcategories: [
			{
				id: 'subcategory-21',
				title: 'Slimme thermostaat',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Met een slimme thermostaat en slimme radiatorknoppen kun je voor elke ruimte een andere temperatuur instellen. Met geofencing kun je via een GPS laten weten of je wel of niet thuis bent. Ben je onderweg naar huis? Stel je verwarming dan vanaf je telefoon alvast in op de juiste temperatuur. Zo bespaar je energie.',
				attachment: 'slimmethermostaat.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Bekijk het assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=slimme%20thermostaat',
					},
				],
			},
			{
				id: 'subcategory-22',
				title: 'Thermostatische radiatorknop',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Slimme radiatorknoppen reageren op de temperatuur van de omgeving. Daalt de temperatuur in een ruimte onder de vooraf ingestelde waarde? Dan opent de radiatorkraan automatisch. Als de juiste temperatuur is bereikt, sluit de radiatorkraan vanzelf. Zo kun je elke ruimte energiebesparend verwarmen!',
				attachment: 'thermostatische-radiatorknop.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Bekijk het assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=thermostatische%20radiatorkraan',
					},
				],
			},
			{
				id: 'subcategory-23',
				title: 'Radiator ventilator',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Radiator ventilatoren zorgen ervoor dat de ruimte al in de helft van de tijd op temperatuur is. Zo kun je tot wel 30% energie besparen. Radiator ventilatoren installeren is bovendien heel eenvoudig: hiervoor gebruik je de verstelbare magneetbevestiging. Met slimme radiator ventilatoren bespaar je dus heel makkelijk geld en energie.',
				attachment: 'https://www.youtube.com/embed/oATPuqaT3mE',
				attachmentType: 'youtube',
				buttons: [
					{
						id: 'button 1',
						text: 'Assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=Radiator+ventilator',
					},
					{
						id: 'button 2',
						text: 'Stappenplan',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusadvies/a/slimme-radiator-ventilator-aansluiten',
					},
				],
			},
			{
				id: 'subcategory-24',
				title: 'Thermostatische mengkraan',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Met een thermostatische mengkraan krijg je het water altijd snel op de juiste temperatuur. Veel van deze kranen zijn voorzien van de EcoSmart ecostop-functie. Deze handige functie zorgt ervoor dat de kraan niet meer dan 10 liter water per minuut doorlaat. Zo verbruik je met een thermostatische kraan zo’n 60 procent minder water.',
				attachment: 'thermostatische-mengkraan.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Bekijk het assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=thermostatische+mengkraan',
					},
				],
			},
		],
	},
	{
		id: 'category-8',
		subcategories: [
			{
				id: 'subcategory-25',
				title: 'Regenton/Hydroblob',
				subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
				content:
					'Met een Hydroblob vang je overtollig hemelwater op dat vervolgens langzaam wordt afgegeven aan de grond. Door ook een regenton aan te sluiten vang je water op dat je op een later moment kunt gebruiken. Bijvoorbeeld voor het wassen van je auto of het besproeien van de tuin. Zo hoef je hier geen drinkwater meer voor te gebruiken.',
				attachment: 'regenton.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=hydroblob',
					},
					{
						id: 'button 2',
						text: 'Stappenplan',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusadvies/a/drainage-met-hydroblobs',
					},
				],
			},
			{
				id: 'subcategory-26',
				title: 'Slim bewateren',
				subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
				content:
					'Met slimme bewatering wordt je tuin altijd en automatisch goed onderhouden. Je bestuurt het slimme bewateringssysteem met een app. Hiermee registreer je bodemvochtigheid, temperatuur en lichtintensiteit. Is de grond te droog? Dan geeft het systeem een signaal af dat de tuin besproeid moet worden. Je kunt ook specifieke sproeitijden instellen.',
				attachment: 'slim-bewateren.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=Slim+bewateren',
					},
					{
						id: 'button 2',
						text: 'Stappenplan',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/slim-bewateren',
					},
				],
			},
			{
				id: 'subcategory-27',
				title: 'Compostbak',
				subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
				content:
					'Met een compostbak creëer je milieuvriendelijke bemesting voor de planten en het gazon in je tuin. In een compostbak kun je tuinafval en huishoudelijke afvalresten kwijt. Ook gft-afval mag in de compostbak. Denk aan schillen, etensresten, lege eierdozen en koffieprut. Je kunt zelf een compostbak maken of een compostbak kopen.',
				attachment: 'compost.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=compostbak',
					},
					{
						id: 'button 2',
						text: 'Stappenplan',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusadvies/a/compostbak-maken',
					},
				],
			},
			{
				id: 'subcategory-28',
				title: 'Sensorverlichting',
				subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
				content:
					'Tuinverlichting zorgt ook ’s avonds voor een gezellige sfeer in je tuin. Wil je energie besparen met tuinverlichting? Kies dan voor sensorverlichting. Er zijn verschillende buitenlampen die een ingebouwde dag/nacht-sensor hebben. Deze sensor zorgt ervoor dat de tuinverlichting pas aangaat wanneer de schemer valt.',
				attachment: 'sensor.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Assortiment',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/assortiment/zoeken?text=sensor%20verlichting',
					},
					{
						id: 'button 2',
						text: 'Stappenplan',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusadvies/a/bewegingssensor-aansluiten',
					},
				],
			},
		],
	},
	{
		id: 'category-9',
		subcategories: [
			{
				id: 'subcategory-29',
				title: 'Glasisolatie',
				subtitle: 'KLUSNIVEAU: KLUSSERVICE',
				content:
					'Veel woningen hebben allang geen enkelglas of dubbelglas meer. Vanaf 2000 werden woningen steeds vaker voorzien van HR++ glas. Dit is dubbelglas met een isolerend gas ertussen en een isoleren coating. Triple glas (HR+++ glas) zorgt voor de allerhoogste besparing. Hiermee bespaar je tot bijna €500 aan energiekosten per jaar en stoot je jaarlijks gemiddeld 1.150 minder CO2 uit.',
				attachment: 'klusservice.jpg',
				attachmentType: 'Image',
				buttons: [
					{
						id: 'button 1',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren?redirect=true',
					},
				],
			},
		],
	},
	{
		id: 'category-10',
		subcategories: [
			{
				id: 'subcategory-30',
				title: 'Buisisolatie',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Je verwarmingsbuizen isoleren? Met leidingisolatie voorkom je onnodige warmteafgifte van cv-leidingen en warmwaterleidingen. Dat scheelt een hoop warmteverlies. Let op: isoleer alleen leidingen die door onverwarmde ruimtes lopen. Verwarmde ruimtes worden vaak intensiever gebruikt, waardoor het rendement te laag is. Bovendien is het isolatiemateriaal niet zo mooi als het in het zicht hangt.',
				attachmentType: 'youtube',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/verwarmingsbuizen-isoleren',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-31',
				title: 'Radiatorfolie',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Wist je dat een radiator ook aan de achterkant warmte afgeeft? Zeker bij een niet-geïsoleerde borstwering (het muurgedeelte onder het raamkozijn) glipt dan veel warmte naar buiten. Dat is niet alleen zonde van de energie, maar ook van je stookkosten. Om onnodig warmteverlies te voorkomen, kun je radiatorfolie plaatsen achter je verwarming.',
				attachment: 'https://www.youtube.com/embed/4UFu1_7aeq8',
				attachmentType: 'youtube',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/radiatorfolie-aanbrengen',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-32',
				title: 'Tochtband',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Tochtband aanbrengen is een eenvoudige manier om kieren rond je ramen en deuren te dichten. Hiermee houd je de kou buiten en de warmte binnen. Doordat opgewekte warmte niet meer door de kieren ontsnapt hoeft de verwarming minder hard te stoken. Zo verhoog je je wooncomfort en bespaar je op je energierekening. Een duurzame oplossing!',
				attachment: 'https://www.youtube.com/embed/XyPNye5f_C8',
				attachmentType: 'youtube',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/tochtband-aanbrengen',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-33',
				title: 'Tochtstrip',
				subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
				content:
					'Tochtstrips kun je gebruiken voor kieren rondom bewegende delen, zoals ramen en deuren. Een tochtstrip is een hard profiel, gemaakt van kunststof of aluminium met een borstel of flexibele rubberen rand. Deze strip voorkomt tocht, waardoor het binnen warm blijft en de verwarming minder hoeft te stoken. Goed voor het milieu en voor je portemonnee.',
				attachment: 'tochtstrip.jpg',
				attachment: 'tochtstrip.jpg',
				attachmentType: 'image',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/tochtband-aanbrengen',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-34',
				title: 'Naden kitten',
				subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
				content:
					'Kit kun je gebruiken voor de meest uiteenlopende klussen. Hiermee kun je kieren en naden afsluiten. Bij bewegende vlakken kun je met kit een elastische verbinding maken. Een eenvoudige klus die je makkelijk uitvoert en waar je niet veel klusgereedschap of materialen voor nodig hebt. Zo voorkom je warmteverlies en bespaar je energie!',
				attachment: 'naden-kitten.jpg',
				attachmentType: 'image',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/hoe-kitten',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-35',
				title: 'Raamfolie/Thermocover',
				subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
				content:
					'Bevatten de ramen in je huis nog enkelglas of dubbelglas? Met raamfolie of glasfolie kun je op een eenvoudige en goedkope manier de isolatiewaarde van het glas in je woning verbeteren. Met raamfolie herstel je de isolatielaag van je ramen en houd je kou buiten. Op deze manier blijft je huis lekker warm en bespaar je stookkosten.',
				attachment: 'raamfolie.jpg',
				attachmentType: 'image',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/raamfolie-aanbrengen',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
			{
				id: 'subcategory-36',
				title: 'Deurdranger',
				subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
				content:
					'Met een deurdranger op je deur zorg je ervoor dat je buitendeur altijd automatisch netjes dichtgaat. Deurdrangers vind je bij GAMMA in diverse vormen en maten. Voor een buitendeur of een zware binnendeur heb je een deurdranger met een schaararm nodig. Met een deurdranger heb je geen onnodig warmteverlies meer en bespaar je op je energierekening.',
				attachment: 'https://www.youtube.com/embed/TPSjyzxn3b8',
				attachmentType: 'youtube',
				buttons: [
					{
						id: 'button 1',
						text: 'Stappenplan',
						color: 'white',
						background: '#69AA0A',
						link: 'https://www.gamma.nl/klusadvies/a/deurdranger-monteren',
					},
					{
						id: 'button 2',
						text: 'Klusservice',
						color: 'white',
						background: '#003878',
						link: 'https://www.gamma.nl/klusservice/laten-isoleren',
					},
				],
			},
		],
	},
]

export default data
